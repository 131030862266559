/**
 * Card grid component that defines how home page layout for cards looks
 *
 */

import React from "react"
import PropTypes from "prop-types"

import styled from "styled-components"

import "./layout.css"
import Layout from "./layout"

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-auto-flow: dense; /*since featured takes up 2 cols, this fills up the empty grid cells*/
  grid-gap: 10px;
  max-width: 1240px;
  margin: 0, auto;

  /* for tablets */
  @media screen and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  /* for desktop */
  @media screen and (min-width: 850px) {
    grid-template-columns: repeat(4, 1fr);
  }
  /* for widescreen */
  @media screen and (min-width: 1240px) {
    grid-template-columns: repeat(8, 1fr);
  }
`

// Render goes here
const CardGrid = ({ children }) => (
  <Layout>
    <CardContainer>{children}</CardContainer>
  </Layout>
)

CardGrid.propTypes = {
  children: PropTypes.node.isRequired,
}

export default CardGrid
