import React from "react"
// import { Link } from "gatsby"

import CardGrid from "../components/card-grid"
//import Image from "../components/image"
import SEO from "../components/seo"
//import Listing from "../components/listing"
import Card from "../components/card"

const IndexPage = () => (
  <CardGrid>
    <SEO title="Home" />
    {/* <Listing /> */}
    <Card />
    {/* <Card />
    <Card />
    <Card />
    <Card />
    <Card />
    <Card />
    <Card />
    <Link to="/page-2/">Go to page 2</Link> */}
  </CardGrid>
)

export default IndexPage
