import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import tw from "tailwind.macro"
import "./layout.css"
// import logo from "../images/gatsby-icon.png"

//graphql query here
//! note the 50 card limit. Pagination needs to be implemented
const ARTICLE_LISTING_QUERY = graphql`
  query ArticleListingQuery {
    allSanityArticle(limit: 50, sort: { order: DESC, fields: [publishedAt] }) {
      edges {
        node {
          title
          sponsored
          featured
          slug {
            current
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          tweet
          id
          categories {
            title
          }
          mainImage {
            asset {
              fixed(width: 400) {
                src
              }
            }
          }
          author {
            name
            image {
              asset {
                fixed(width: 50, height: 50) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`

const CardWrapper = styled.div`
  /* display: grid; */
  /* max-width: 900px; */
  /* grid-template-columns: repeat(11, minmax(40px, 80px)); */
  /* grid-template-rows: repeat(11, 50px);  */
  /* grid-gap: 5px; mt-4 */
  display: flex;
  flex-direction: column;
  min-height: 100%;
  box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);
  position: relative;
  top: 0;
  transition: all 0.1s ease-in;
  ${tw`bg-gray-200 rounded`};

  &:hover {
    top: -2px;
    box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);
  }

  @media only screen and (min-width: 1240px) {
    grid-column: span 2;
  }
`

const ImageDiv = styled.div`
  /* grid-column: 1/12;
  grid-row: 1/7; */
  padding-bottom: 60%;
  ${tw`align-middle bg-cover bg-center rounded-t text-center overflow-hidden`};
`

const TextContainer = styled.div`
  ${tw`px-2 py-2`};
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    ${tw`m-0 m-4`};
    color: hsl(22, 78%, 55%);
  }
  p {
    ${tw`text-gray-900 mx-4`};
    flex: 1;
    line-height: 1.4;
  }
`

const StyledLink = styled(Link)`
  ${tw`text-gray-800 no-underline`};
`
const Author = styled.div`
  ${tw`w-full text-center`}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

const AuthorImageDiv = styled.div`
  ${tw`bg-center bg-cover w-20 h-20 m-2 rounded-full`}
`

const AuthorMeta = styled.div`
  align-self: center;
  justify-self: center;
`
const Tags = styled.div`
  /* grid-column: 9/12;
  grid-row: 1/3; */
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;
  span {
    ${tw`inline-block bg-gray-400 mx-2 my-2 rounded-full px-2 py-1 text-xs font-bold text-gray-800`};
  }
`

const ReadButton = styled.button`
  /* background: hsl(22, 100%, 86%); */
  /* justify-self: flex-end; */
  ${tw`text-gray-800 font-light mb-1 bg-gray-100 hover:bg-gray-400 py-2 px-4 border border-gray-400 rounded`};
`

const Card = () => (
  <StaticQuery
    query={ARTICLE_LISTING_QUERY}
    render={({ allSanityArticle }) =>
      allSanityArticle.edges.map(({ node }) => (
        <CardWrapper
          key={node.id}
          // style={{ background: node.featured ? "#FEB2B2" : "#EDF2F7" }}
          className={
            node.featured ? "featured" : node.sponsored ? "sponsored" : ""
          }
          //bos video 5 10:20 hs another way to handle this more gracefully
        >
          <ImageDiv
            style={{
              backgroundImage: "url(" + node.mainImage.asset.fixed.src + ")",
            }}
          ></ImageDiv>
          <TextContainer>
            <StyledLink to={`/articles/${node.slug.current}`}>
              <h1>{node.title}</h1>
            </StyledLink>
            <p>{node.tweet}</p>
            <Link to={`/articles/${node.slug.current}`}>
              <ReadButton>Read</ReadButton>
            </Link>
            <Author>
              <AuthorImageDiv
                style={{
                  backgroundImage:
                    "url(" + node.author.image.asset.fixed.src + ")",
                }}
              ></AuthorImageDiv>
              <AuthorMeta>
                <p>
                  {node.author.name} - {node.publishedAt}
                </p>
              </AuthorMeta>
            </Author>
          </TextContainer>
          <Tags>
            {node.categories.map(({ title }) => (
              <span>{title}</span>
            ))}
          </Tags>
        </CardWrapper>
      ))
    }
  />
)

export default Card
